export function fancyboxOpen() {
    const $f = $('.fancybox-open');

    if(!$f.length) return;

    $f.fancybox({
        infobar: false,
        smallBtn: true,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>'
        }
    });
}
