import Swiper, { Navigation } from 'swiper';

export function homePartnersSlider() {
    const $s = $('.home-partners-slider');

    if(!$s.length) return;

    Swiper.use([Navigation]);

    const slider = new Swiper('.home-partners-slider', {
        navigation: {
            nextEl: '.home-partners-slider-next',
            prevEl: '.home-partners-slider-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                /*spaceBetween: 20*/
            },
            650: {
                slidesPerView: 2,
                /*spaceBetween: 30*/
            },
            1280: {
                slidesPerView: 3,
                /*spaceBetween: 40*/
            },
            1700: {
                slidesPerView: 4,
                /*spaceBetween: 40*/
            }
        }
    });

}