export function galleryInit() {
  const $l = $('.load-more-gallery');

  if(!$l.length) return;

  $l.on('click', (e) => {
    e.preventDefault();

    $.ajax({
      url: '/photogallery?page=' + $l.data('page'),
      success: data => {
        $('.gallery-list').append(data.content);
        if(data.next_page) {
          $l.data('page', data.next_page);
        }else{
          $l.parent().hide();
        }
      }
    })
  });
}
