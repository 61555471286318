import Swiper, { Mousewheel, Navigation, Pagination } from 'swiper';
import {addZero} from '@/modules/functions';

export function truckSlider() {
    const $s = $('.truck-slider');

    if(!$s.length) return;

    Swiper.use([Navigation, Pagination, Mousewheel]);

    const slider = new Swiper('.truck-slider', {
        cssMode: true,
        navigation: {
            nextEl: '.truck-slider-next',
            prevEl: '.truck-slider-prev',
        },
        pagination: {
            el: '.swiper-pagination',
            type: 'fraction',
            formatFractionCurrent: (number) => {
                return addZero(number);
            },
            formatFractionTotal: (number) => {
                return addZero(number);
            }
        },
        mousewheel: true
    });
}
