import Swiper, { Mousewheel, Navigation } from 'swiper';
import { addZero } from "@/modules/functions";

export function productSlider() {
  const $s = $(".product-slider");

  if (!$s.length) return;

  Swiper.use([Navigation, Mousewheel]);

  const slider = new Swiper(".product-slider", {
    cssMode: true,
    navigation: {
      nextEl: ".product-thumb-slider-next",
      prevEl: ".product-thumb-slider-prev"
    },
    mousewheel: true
  });
}
