import {isEmpty} from '@/modules/functions';

export class HomeTabs {
    $homeTabs = $('.home-tabs-section');
    $categories = this.$homeTabs.find('.categories-panes');
    $categoriesNav = this.$homeTabs.find('.categories-navigation');
    $subcategories = this.$homeTabs.find('.subcategories-panes');
    $subcategoriesNav = this.$homeTabs.find('.subcategory-navigation');
    $products = this.$homeTabs.find('.products-panes');
    $productsNav = this.$homeTabs.find('.product-navigation');
    timeout = 300;

    constructor() {
        if (this.$homeTabs.length) {
            this.init();
        }
    }

    init() {
        const $catFirst = this.$categoriesNav.find('li').eq(0);
        const $subcatFirst = this.$subcategoriesNav.find('li').eq(0);
        const $productFirst = this.$productsNav.find('li').eq(0);

        $catFirst.addClass('active');
        $('#' + $catFirst.data('tab')).addClass('active');

        $subcatFirst.addClass('active');
        $('#' + $subcatFirst.data('tab')).addClass('active');

        $productFirst.addClass('active');
        $('#' + $productFirst.data('tab')).addClass('active');

        this.bindClick(
            this.$categories,
            this.$categoriesNav,
            {
                subcategories: true,
                products: true
            }
        );

        this.bindClick(
            this.$subcategories,
            this.$subcategoriesNav,
            {
                products: true
            }
        );

        this.bindClick(
            this.$products,
            this.$productsNav
        );
    }

    /**
     *
     * @param $panes
     * @param {string} tab
     * @param {Object} clear
     * @param {boolean} clear.subcategories
     * @param {boolean} clear.products
     */

    changeTab($panes, tab, clear) {
        const $active = $panes.find('>.active');
        const $tab = $('#' + tab);

        $active.removeClass('active').addClass('hide');

        setTimeout(() => {
            $active.removeClass('hide');

            if(clear.subcategories) this.clearSubcategories($tab);
            if(clear.products) this.clearProducts($tab);

            $tab.addClass('active');
        }, this.timeout);
    }

    /**
     *
     * @param $panes
     * @param $nav
     * @param {Object=} clear
     * @param {boolean=} clear.subcategories
     * @param {boolean=} clear.products
     */

    bindClick($panes, $nav, clear) {

        if (isEmpty(clear)) {
            clear = {
                subcategories: false,
                products: false
            }
        }

        if (!clear.subcategories) clear.subcategories = false;
        if (!clear.products) clear.products = false;

        $nav.find('li').on('click', (e) => {

            const $current = $(e.currentTarget);
            const tab = $current.data('tab');

            if($current.is('.active')) return;

            $nav.find('li.active').removeClass('active');
            $current.addClass('active');

            this.changeTab($panes, tab, clear);
        });
    }

    clearSubcategories() {
        this.$subcategoriesNav.each((index, element) => {
            const $e = $(element);

            $e.find('.active').removeClass('active');
            $e.find('li').eq(0).addClass('active');
        });

        this.$subcategories.each((index, element) => {
            const $e = $(element);

            $e.find('>.subcategory-pane.active').removeClass('active');
            $e.find('>.subcategory-pane').eq(0).addClass('active');
        });
    }

    clearProducts() {
        this.$productsNav.each((index, element) => {
            const $e = $(element);

            $e.find('.active').removeClass('active');
            $e.find('li').eq(0).addClass('active');
        });

        this.$products.each((index, element) => {
            const $e = $(element);

            $e.find('>.product-pane.active').removeClass('active');
            $e.find('>.product-pane').eq(0).addClass('active');
        });
    }
}
