export function initVideo() {
    const $v = $('.video-wrapper');
    const $cw = $('.cover-wrapper');

    if(!$v.length) return;

    $cw.on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        $(this).fadeOut(300);
        $(this).prev('.youtube-video')[0].contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    });
}
