const animationTime = 500;
const $b = $('body');
const $op = $('.open-popup');
let hiding = false;

function hidePopup($popup, $close) {
    hiding = true;

    $popup.addClass('fade-out');
    $b.addClass('hiding-popup');

    setTimeout(() => {
        $popup.removeClass('fade-out show');
        $b.removeClass('hiding-popup show-popup');
        $close.unbind('click.close-popup');
        $popup.unbind('click.outside');
        hiding = false;
    }, animationTime)
}

export function showPopup($popup) {
    const $close = $popup.find('.close');
    const $inner = $popup.find('.popup-inner');

    $popup.bind('click.outside', function (e) {
        if(!$(e.target).closest($inner).length && !hiding) {
            hidePopup($popup, $close);
        }
    });

    $b.addClass('show-popup');
    $popup.addClass('show');

    $close.bind('click.close-popup', function (e) {
        e.preventDefault();
        if(!hiding) hidePopup($popup, $close);
    });
}

export function initPopup() {

    $op.on('click', function (e) {
        e.preventDefault();
        const href = $(this).attr('href');
        const $popup = $(href);

        $popup.find('#order_item_id').val($(this).data('item'))

        showPopup($popup);
    });
}
