import Swiper, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';

export function homeHeroSlider() {

	const $s = $('.home-hero-slider');

	if(!$s.length) return;

	Swiper.use([Pagination, EffectFade, Autoplay]);

	const slider = new Swiper('.home-hero-slider', {
		effect: 'fade',
		autoplay: {
			delay: 5000,
			pauseOnMouseEnter: true
		},
		fadeEffect: {
			crossFade: true
		},
		pagination: {
			el: '.home-hero-slider-pagination',
			clickable: true
		}
	});
}
