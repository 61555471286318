import Swiper, { Navigation } from "swiper";

export function historyCarousel() {
    const $s = $('.history-carousel');

    if(!$s.length) return;

	Swiper.use([Navigation]);

    const slider = new Swiper('.history-carousel', {
        slidesPerView: 2,
        spaceBetween: 88,
        navigation: {
            nextEl: '.history-carousel-next',
            prevEl: '.history-carousel-prev',
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                spaceBetween: 50,
            },
            1199: {
                slidesPerView: 2,
                spaceBetween: 88,
            }
        }
    });
}

