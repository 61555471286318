const $fv = $('.fancy-video');

export function videoGallery() {

    if(!$fv.length) return;

    initVideoFancybox();

    const $l = $('.load-more-video-gallery');

    $l.on('click', (e) => {
        e.preventDefault();

        $.ajax({
            url: '/videogallery?page=' + $l.data('page') + ($l.data('category') ? '&category='+$l.data('category') : ''),
            success: data => {
                $('.video-gallery-list').append(data.content);
                if(data.next_page) {
                  $l.data('page', data.next_page);
                } else {
                  $l.hide();
                }

                setTimeout(() => {
                    initVideoFancybox();
                }, 300)
            }
        });
    });
}

function initVideoFancybox() {
    $('.fancy-video').fancybox({
        infobar: false,
        smallBtn: true,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-video-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>',
        }
    });
}
