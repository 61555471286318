import {isMobile} from '@/modules/functions';
import Swiper, { Navigation } from 'swiper';

export function homeNewsMobile() {
    const $l = $('.home-news-list');

    if(!$l.length || !isMobile()) return;

    $l.addClass('swiper');
    $l.find('.item').addClass('swiper-slide');
    $l.find('.item').wrapAll('<div class="swiper-wrapper"></div>');

    $l.append(`
         <div class="controls">
             <div class="home-news-slider-prev">
                <div class="if if-long-arrow-left"></div>
             </div>
             <div class="home-news-slider-next">
                <div class="if if-long-arrow-right"></div>
             </div>
         </div>
    `);

    Swiper.use([Navigation]);

    const slider = new Swiper('.home-news-list', {
        autoHeight: true,
        spaceBetween: 20,
        navigation: {
            nextEl: '.home-news-slider-next',
            prevEl: '.home-news-slider-prev',
        }
    });

}
