import Swiper from 'swiper';

export function newsSlider() {
    const $s = $('.news-slider');

    if(!$s.length) return;

    const slider = new Swiper('.news-slider', {
        navigation: {
            nextEl: '.news-slider-next',
            prevEl: '.news-slider-prev',
        }
    });
}
