import Swiper, { EffectFade, Navigation, Thumbs } from "swiper";

export function gallerySlider() {

    initFancybox();

    const $g = $('.gallery-slider');

    if(!$g.length) return;
Swiper.use([Navigation, Thumbs, EffectFade]);

	const thumbSlider = new Swiper(".gallery-slider-thumbs", {
		slidesPerView: "auto",
		watchSlidesProgress: true,
		breakpoints: {
			0: {
				spaceBetween: 30,
			},
			1199: {
				spaceBetween: 55,
			},
		},
	});

	const slider = new Swiper(".gallery-slider", {
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		thumbs: {
			swiper: thumbSlider,
			autoScrollOffset: 1,
		},
		navigation: {
			nextEl: ".gallery-slider-next",
			prevEl: ".gallery-slider-prev",
		},
	});

	const thumbNext = document.querySelector(".gallery-slider-thumbs-next");
	const thumbPrev = document.querySelector(".gallery-slider-thumbs-prev");
	if (thumbNext) {
		thumbNext.onclick = (e) => {
			e.preventDefault();
			slider.slideNext();
		};
	}
	if (thumbPrev) {
		thumbPrev.onclick = (e) => {
			e.preventDefault();
			slider.slidePrev();
		};
	}
}

function initFancybox() {

    const $g = $('[data-fancybox="gallery"]');

    if(!$g.length) return;

    $g.fancybox({
        infobar: false,
        smallBtn: true,
        btnTpl: {
            smallBtn:
                '<button type="button" data-fancybox-close class="close-gallery">' +
                '<i class="if if-close"></i>' +
                '</button>',
            arrowLeft:
                '<button data-fancybox-prev class="gallery-prev">' +
                '<i class="if if-long-arrow-left"></i>' +
                '</button>',

            arrowRight:
                '<button data-fancybox-next class="gallery-next">' +
                '<i class="if if-long-arrow-right"></i>' +
                '</button>',
        }
    });
}
