export function headerInit() {
	const header = document.querySelector('.site-header');
	const navigation = document.querySelector('.header-navigation');
	checkScroll(header, navigation);
	window.addEventListener('scroll', () => {
		checkScroll(header, navigation);
	})
}


function checkScroll(headerElement, navigationElement) {
	const scrolledClass = 'scrolled';
	const isScrolled = window.scrollY > 100;

	isScrolled ? headerElement.classList.add(scrolledClass) : headerElement.classList.remove(scrolledClass);
	isScrolled ? navigationElement.classList.add(scrolledClass) : navigationElement.classList.remove(scrolledClass);
}

