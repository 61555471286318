export class HistorySlider {
    slideCount;
    slideHeight;
    containerHeight;
    currentSlide = 0;
    nextSlide;
    tabTransition = 500;
    $slider = $('.history-slider');


    constructor() {
        if(this.$slider.length) {
            this.$slides = this.$slider.find('.history-slider-slide');
            this.$container = this.$slider.find('.history-slider-container');
            this.$prev = this.$slider.find('.history-slider-prev');
            this.$next = this.$slider.find('.history-slider-next');
            this.$tabs = $('.history-tabs');

            this.init();
        }
    }

    init() {
        this.slideCount = this.$slides.length;
        this.slideHeight = this.$slides.height();
        this.nextSlide = 0;

        this.containerHeight = this.slideHeight * this.slideCount;

        this.$container.height(this.containerHeight);

        this.changeSlide();
        this.changeTab();
        this.eventClickSlide();

        this.$prev.on('click', () => {
            this.goPrevSlide();
        });

        this.$next.on('click', () => {
            this.goNextSlide();
        });
    }

    eventClickSlide() {
        this.$slides.on('click', (e) => {
           const $t = $(e.currentTarget);
           this.nextSlide = $t.index();


            this.changeSlide();
        });
    }

    goPrevSlide() {
        if (this.currentSlide > 0) {
            this.nextSlide = this.currentSlide - 1;

            this.changeSlide();
        }
    }

    goNextSlide() {
        if (this.currentSlide < this.slideCount - 1) {
            this.nextSlide = this.currentSlide + 1;
            this.changeSlide();
        }
    }

    changeSlide() {

        this.$slides.eq(this.currentSlide).removeClass('active');

        let offset = (this.nextSlide - 1) * this.slideHeight;

        if(offset != 0) offset = offset * -1;

        this.$container.css('transform', 'translateY('+ offset +'px)');

        this.currentSlide = this.nextSlide;
        this.$slides.eq(this.currentSlide).addClass('active');

        this.changeTab();
    }

    changeTab() {
        const $active = this.$tabs.find('.active');

        $active.removeClass('active').addClass('hide');

        setTimeout(() => {
            $active.removeClass('hide');
            $(this.$slides.eq(this.currentSlide).data('tab')).addClass('active');
        }, this.tabTransition);
    }
}
