import IMask from 'imask';

export function initContactMask() {
  if (document.getElementById('feedback_contact')) {
    var dynamicMask = IMask(
      document.getElementById('feedback_contact'),
      {
        mask: [
          {
            mask: '+{7}(000)000-00-00'
          },
          {
            mask: /^\S*@?\S*$/
          }
        ]
      }
    );
  }
}
