import $ from "jquery";
import "@fancyapps/fancybox/dist/jquery.fancybox.min";
import {
  burgerInit,
  catalogCaption,
  fancyboxOpen,
  formControl,
  galleryInit,
  gallerySlider,
  historyCarousel,
  homeVideoSlider,
  homePartnersSlider,
  initCounters,
  initPopup,
  initVideo,
  navigationInit,
  newsSlider,
  productSlider,
  truckSlider,
  videoGallery,
  initContactMask,
  disableRightClick
} from "./components";
import { HomeTabs } from "./modules/home-tabs";
import { homeNewsMobile } from "./components/home-news-mobile";
// import {contactMap} from './modules/contact-map';
import { HistorySlider } from "./modules/history-slider";
import { headerInit } from '@/components/header';
import { homeHeroSlider } from '@/components/home-hero-slider';

$(document).ready(init);

function init() {
  headerInit();
  burgerInit();
  navigationInit();
  truckSlider();
  initVideo();
  homeVideoSlider();
  homePartnersSlider();
  initCounters();
  historyCarousel();
  homeNewsMobile();
  galleryInit();
  videoGallery();
  newsSlider();
  formControl();
  // contactMap();
  gallerySlider();
  catalogCaption();
  fancyboxOpen();
  productSlider();
  initPopup();
  initContactMask();
  homeHeroSlider();

  disableRightClick();

  new HomeTabs();
  new HistorySlider();
}
