export function catalogCaption() {
    const $c = $('.catalog-navigation');

    if(!$c.length) return;

    $c.find('a').on('mouseenter', function () {
        $c.find('.caption-list .active').removeClass('active');

        const $caption = $($(this).data('caption'));

        $caption.addClass('active');
    });

    $c.find('a').on('mouseleave', function () {
        $c.find('.caption-list .active').removeClass('active');
    });

   /* if($(window).width() < 1200) {
        let used;

        $c.find('.navigation a').on("click", function(e){
            if( used !== this) {
                used = this;
                e.preventDefault();
            }
        });
    }*/

}
