export function burgerInit() {
    const $b = $('.burger-button');
    const $h = $('html');
    const showClass = 'show-navigation';
    const hideClass = 'hide-navigation';
    const timeout = 500;

    $b.on('click', () => {

        if($h.is('.' + showClass)) {
            $h.removeClass(showClass).addClass(hideClass);
            $('.header-navigation').find('.parent.active').next().slideUp(300);
            $('.header-navigation').find('.parent.active').removeClass('active');
            setTimeout(() => {
                $h.removeClass(hideClass);
            }, timeout);
        } else {
            $h.addClass(showClass);
        }
    });
}
